import loadable from '@loadable/component';

import { EventBattle } from '@fie/analytics-library/src/types/Battle';

import { Site } from '../../core/modules/sites/types/Site';
import { SeasonalEventMerchant } from '../../core/types/SeasonalEvent';
import { getHawk } from '../../utils/global';

const lazyGetCustomTrackingIdServerside = loadable.lib(
  () => import('@fie/analytics-library/build/getCustomTrackingIdServerside'),
);

const lazyGetCustomTrackingId = loadable.lib(
  () => import('@fie/analytics-library/build/getCustomTrackingId'),
);

export const getSeasonalMerchantCustomTrackingId = async (
  articleUrl: string,
  site: Site,
  merchant: SeasonalEventMerchant,
  area: string,
  battle: EventBattle | null,
): Promise<string> => {
  const { customTrackingId: forcedCustomTrackingId } = getHawk();
  if (process.env.IS_SERVERSIDE) {
    const { getCustomTrackingIdServerside } = await lazyGetCustomTrackingIdServerside.load();
    return getCustomTrackingIdServerside(
      {
        pageUrl: articleUrl,
        site,
        merchant,
        area,
        battle,
      },
      forcedCustomTrackingId,
    );
  }
  const { getCustomTrackingId } = await lazyGetCustomTrackingId.load();
  return getCustomTrackingId(
    {
      pageUrl: articleUrl,
      site,
      merchant,
      area,
      battle,
    },
    forcedCustomTrackingId,
  );
};
